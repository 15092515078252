/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import {Fragment} from "react";
import emoji from "react-easy-emoji";
import ItemCarousel from "./components/carousel/ItemCarousel";
import "./assets/iconBundle/icons-bundle.js";
import { Typography } from "@mui/material";

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Brian Stocco",
  title: "Brian Stocco",
  subTitle:
    "I'm a software developer working mainly on web applications and web services using Java and Node.js. I have experience building scalable distributed systems deployed to the cloud. I enjoy problem solving and learning new things.",
  resumeLink:
    "https://drive.google.com/uc?export=download&id=1_N5y5sjfi2Szf-xQYASTCChwOsJnUct6", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  linkedin: "https://www.linkedin.com/in/bstocco",
  gmail: "form",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Skills",
  subTitle:
    "Primarily backend and DevOps skills, with experience in data processing for analytics solutions",
  skills: [
    "Strong debugging and technical troubleshooting skills",
    "Experience working with remote teams across many timezones",
    "Presentation and customer-facing documentation writing skills/experience"
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Java",
      iconLight: "skill-icons:java-light",
      iconDark: "skill-icons:java-dark"
    },
    {
      skillName: "Spring",
      iconLight: "skill-icons:spring-light",
      iconDark: "skill-icons:spring-dark"
    },
    {
      skillName: "Maven",
      iconLight: "skill-icons:maven-light",
      iconDark: "skill-icons:maven-dark"
    },
    {
      skillName: "Jenkins",
      iconLight: "skill-icons:jenkins-light",
      iconDark: "skill-icons:jenkins-dark"
    },
    {
      skillName: "NodeJS",
      iconLight: "skill-icons:nodejs-light",
      iconDark: "skill-icons:nodejs-dark"
    },
    {
      skillName: "Python",
      iconLight: "skill-icons:python-light",
      iconDark: "skill-icons:python-dark"
    },
    {
      skillName: "Flink",
      iconLight: "local:flink-light",
      iconDark: "local:flink-dark"
    },
    {
      skillName: "Kafka",
      iconLight: "local:kafka-light",
      iconDark: "local:kafka-dark"
    },
    {
      skillName: "Redis",
      iconLight: "skill-icons:redis-light",
      iconDark: "skill-icons:redis-dark"
    },
    {
      skillName: "Docker",
      iconLight: "skill-icons:docker",
      iconDark: "skill-icons:docker"
    },
    {
      skillName: "k8s",
      iconLight: "skill-icons:kubernetes",
      iconDark: "skill-icons:kubernetes"
    },
    {
      skillName: "Azure",
      iconLight: "skill-icons:azure-light",
      iconDark: "skill-icons:azure-dark"
    },
    {
      skillName: "Grafana",
      iconLight: "skill-icons:grafana-light",
      iconDark: "skill-icons:grafana-dark"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of Waterloo",
      logo: require("./assets/images/UniversityOfWaterloo.svg.png"),
      subHeader: "Bachelor of Software Engineering",
      duration: "2004 - 2009",
      desc: ""
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Principal Software Developer",
      company: "Genesys",
      companylogo: require("./assets/images/GenesysLightCircle.png"),
      companylogoDark: require("./assets/images/GenesysDarkCircle.png"),
      titleBarColor: "#ff4f1e",
      date: "2012 – 2022",
      desc: "Server and client-side development for real-time analytics projects",
      descBullets: [
        "Technical leader on distributed real-time interaction event stream processing project using Apache Flink",
        "Created CI/CD pipelines working with Maven and npm builds for testing and cloud deployment",
        "Developed new backend architecture for websockets-based real-time metrics dashboard",
        "Developed web services API simulator to assist external developers with creating agent UI customizations"
      ]
    },
    {
      role: "Software Developer",
      company: "Electronic Arts",
      companylogo: require("./assets/images/EALogoLight.jpg"),
      companylogoDark: require("./assets/images/EALogoDark.png"),
      titleBarColor: "black",
      date: "2009 – 2012",
      desc: "Development of cloud-based social features platform and client-side game integration with the platform",
      descBullets: [
        "Integrated social features into popular EA Pogo web games such as Bejeweled, Plants vs Zombies, Sushi Cat and others",
        "Developed Hive/Hadoop based game play-time reporting solution",
        "Developed high-score Facebook friends API leaderboard social integration"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "Some of the projects I've worked on",
  projects: [
    {
      imageLight: require("./assets/images/AgentApiSimulatorLight.png"),
      imageDark: require("./assets/images/AgentApiSimulatorDark.png"),
      projectName: "Genesys Agent API Simulator",
      projectDesc:
        "Tool for developers creating custom integrations with Genesys Workspace",
      descBullets: [
        "A mock backend implemented in NodeJS to allow for client UI customizations development without deploying/provisioning real web services",
        "Includes user-friendly Vue.js UI offering functionality to simulate calls, text messages etc for testing without having to simulate activity on real backend",
        "Originally developed for internal development, later open-sourced under a new repository for use by external developers"
      ],
      footerLink: [
        {
          name: "GitHub Repository",
          url: "https://github.com/GenesysPureEngage/agent-api-simulator"
        }
      ]
    },
    {
      imageLight: require("./assets/images/FlinkLogo.png"),
      imageDark: require("./assets/images/FlinkLogo.png"),
      projectName: "Streaming Interaction Event Processing With Flink",
      projectDesc:
        "Distributed real-time stateful event processing with Apache Flink",
      descBullets: [
        "Performed dynamic key-assignment at runtime using configuration as a datasource for consistency",
        "Applied Prometheus-driven Flink reactive mode autoscaling to respond to changing workloads and reduce resource costs",
        "Implemented complex stateful processing combining multiple data streams",
        "Profiled jobs and developed performance optimizations to achieve high throughput and low latency for real-time metrics"
      ]
    },
    {
      imageLight: require("./assets/images/chart.png"),
      imageDark: require("./assets/images/chart.png"),
      projectName: "Websocket Based Real-Time Metrics Dashboard",
      projectDesc:
        "Migration from Flash-based dashboard to Angular-based dashboard using websockets",
      descBullets: [
        "Extended Spring websockets to work with Spring authentication (beyond STOMP subscription only) to enable per-message user context",
        "Consolidated 3 separate back-ends into a single set of web services to enable development of consolidated front-end",
        "Enabled elimination of client-side performance bottleneck through subscription-based dynamic data retrieval"
      ],
      footerLink: [
        {
          name: "Dashboard",
          dialog: {
            size: "lg",
            content: (
              <>
              <ItemCarousel>
                  <img
                    desc='Original "FrontLine Advisor" Flash dashboard'
                    style={{margin: "auto", display: "block", maxWidth: "90%"}}
                    src={require("./assets/images/AdvisorsFABefore.png")}
                  ></img>
                  <img
                    desc='"FrontLine Advisor" Angular websocket dashboard'
                    style={{margin: "auto", display: "block", maxWidth: "90%"}}
                    src={require("./assets/images/AdvisorsFAAfter.png")}
                  ></img>
                  <img
                    desc='Original "Contact Center Advisor" Flash dashboard'
                    style={{margin: "auto", display: "block", maxWidth: "90%"}}
                    src={require("./assets/images/AdvisorsCCABefore.png")}
                  ></img>
                  <img
                    desc='"Contact Center Advisor" Angular websocket dashboard'
                    style={{margin: "auto", display: "block", maxWidth: "90%"}}
                    src={require("./assets/images/AdvisorsCCAAfter.png")}
                  ></img>
              </ItemCarousel>
              <Typography className="card-subtitle" style={{textAlign: "left", padding: "0px", margin: "0px"}}>From docs.genesys.com</Typography>
              </>
            )
          }
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: "Achievements",
  subtitle: "",

  achievementsCards: [
    {
      title: "Genesys Innovation World Cup 2020 Winner",
      subtitle: "First place team in Genesys Innovation World Cup (IWC) 2020",
      descBullets: [
        <Fragment>
          Led a 5-person “Privacy-Safe Insights” team that presented a{" "}
          <a
            href="https://security.googleblog.com/2019/06/helping-organizations-do-more-without-collecting-more-data.html"
            target="_blank"
          >
            Multi-Party Computation (MPC)
          </a>
          -based concept
        </Fragment>,
        <Fragment>
          Built using the FRamework for Efficient Secure COmputation{" "}
          <a href="https://github.com/aicis/fresco" target="_blank">
            'Fresco'
          </a>{" "}
          framework
        </Fragment>,
        "Team awarded $10,000 prize"
      ],
      imageLight: require("./assets/images/GenesysIWCLight.png"),
      imageDark: require("./assets/images/GenesysIWCDark.png"),
      imageAlt: "Genesys IWC 2020",
      footerLink: [
        {
          name: "Event Promotion Video",
          dialog: {
            style: {aspectRatio: "16/9"},
            size: "lg",
            content: (
              <iframe
                width="100%"
                height="100%"
                style={{border: "0px"}}
                src="https://www.youtube.com/embed/ohCBkqcWfig"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )
          }
        },
        {
          name: "Fresco on GitHub",
          url: "https://github.com/aicis/fresco"
        }
      ]
    },
    {
      title: "Pogo Hackathon - Most Customer Friendly Project",
      subtitle: "Developed Hive/Hadoop based game recommendation system",
      descBullets: [
        "Team leveraged existing game play-time metrics to recommend new games to players",
        "Recommendations based on players with similar preferences, organized into cohorts"
      ],
      imageLight: require("./assets/images/PogoLogo.png"),
      imageAlt: "Pogo Hackathon",
      footerLink: [
        {
          name: "Trophy",
          dialog: {
            style: {
              aspectRatio: "922/1229",
              width: "auto",
              maxHeight: "calc(75% - 64px)"
            },
            size: "md",
            content: (
              <img
                height="100%"
                style={{margin: "auto", display: "block"}}
                src={require("./assets/images/PogoTrophySmallWeb.jpg")}
              ></img>
            )
          }
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Personal Projects, Interests and Blog",
  subtitle:
    "Things I'm interested in, both software related and other things",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://bsoft0.wordpress.com/iee-one-plane-readout-clock-part-1/",
      title: "IEE one-plane readout projection clock project",
      description:
        "Discovering the IEE one-plane display and making a desk clock"
    },
    {
      url: "https://bsoft0.wordpress.com/learning-to-freefly/",
      title: "Skydiving",
      description:
        "My experience learning to freefly"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: "",
  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "",

  // Please Provide with Your Podcast embeded Link
  podcast: [],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "",
  email_address: ""
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  isHireable
};
