import React, {Fragment} from "react";
import Carousel from "react-material-ui-carousel";
import {Paper, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import "./ItemCarousel.scss";

const style = {
  height: "90%"
};

export default function ItemCarousel({children}) {
  const theme = useTheme();
  var isDark = theme.palette.mode == "dark";
  return (
    <div sx={style}>
      <Carousel
        navButtonsAlwaysVisible={true}
        autoPlay={false}
        style={{height: "100%"}}
      >
        {children.map((item, i) => (
          <Item key={i} item={item} isDark={isDark} />
        ))}
      </Carousel>
    </div>
  );
}

function Item(props) {
  return (
    <Paper>
      {props.item}
      <Typography
        className={props.isDark ? "dark-mode card-subtitle" : "card-subtitle"}
      >
        {props.item.props.desc}
      </Typography>
    </Paper>
  );
}
