import React from "react";
import {useForm, ValidationError} from "@formspree/react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";

export default function ContactForm() {
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [state, handleSubmit] = useForm("myyawrwz", {
    data: {"g-recaptcha-response": executeRecaptcha}
  });

  if (state.succeeded) {
    return <p>Thanks for contacting me</p>;
  }

  if (Array.isArray(state.errors) && state.errors.length) {
    return (
      <p>
        Sorry, something went wrong. Please try reaching me on another channel.
      </p>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField helperText="Your name" id="name" label="name" name="name" />
        <TextField
          helperText="Please provide your email address"
          id="email"
          label="email"
          name="email"
        />
        <TextField
          id="message"
          label="Message"
          name="message"
          multiline
          rows={4}
          placeholder="Message"
        />
        <Button variant="contained" type="submit" disabled={state.submitting}>
          Submit
        </Button>
      </Stack>
    </form>
  );
}
