import React, {useContext} from "react";
import "./Footer.scss";
import {Fade} from "react-awesome-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";

export default function Footer() {
  const {isDark} = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          Based on{" "}
          <a href="https://github.com/saadpasta/developerFolio">
            developerFolio
          </a>{" "}
          theme
          <br />
          Additional components provided by{" "}
          <a target="_blank" href="https://mui.com/">
            Google Material UI framework
          </a>
          ,{" "}
          <a target="_blank" href="https://iconify.design/">
            Iconify
          </a>
          , and{" "}
          <a
            target="_blank"
            href="https://www.npmjs.com/package/react-material-ui-carousel"
          >
            react-material-ui-carousel
          </a><br/>
          Illustrations provide by Lottiefiles under <a
            target="_blank"
            href="https://lottiefiles.com/page/license"
          >Lottie Simple License (FL 9.13.21)</a>
        </p>
      </div>
    </Fade>
  );
}
