import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./BasicDialog.scss";

export default function BasicDialog({children, style, name, isDark, size}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span
        className={isDark ? "dark-mode link-tag" : "link-tag"}
        onClick={handleClickOpen}
      >
        {name}
      </span>
      <Dialog fullWidth={size ? true : false} maxWidth={size} PaperProps={style={style}} open={open} onClose={handleClose}>
        <DialogContent style={{ overflow: "hidden" }}>
          <Box
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          ></Box>
          {children}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
