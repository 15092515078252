import React, {useEffect, useState} from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import WorkExperience from "./workExperience/WorkExperience";
import Projects from "./projects/Projects";
import StartupProject from "./StartupProjects/StartupProject";
import Achievement from "./achievement/Achievement";
import Blogs from "./blogs/Blogs";
import Footer from "../components/footer/Footer";
import Talks from "./talks/Talks";
import Podcast from "./podcast/Podcast";
import Education from "./education/Education";
import Profile from "./profile/Profile";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import "./Main.scss";

const Main = () => {
  const darkPref = window.matchMedia('(prefers-color-scheme: dark)');
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);

  const theme = createTheme({
    palette: {
      mode: isDark ? "dark" : "light",
      primary: {
        main: "#58c2aa"
      }
    }
  });

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <ThemeProvider theme={theme}>
        <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
          <Header />
          <Greeting />
          <Skills />
          {/*<StackProgress />*/}
          <Education />
          <WorkExperience />
          <Projects />
          <StartupProject />
          <Achievement />
          <Blogs />
          {/*<Talks />
        //<Podcast />
        <Profile />*/}
          <Footer />
        </StyleProvider>
      </ThemeProvider>
    </div>
  );
};

export default Main;
