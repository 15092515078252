import React from "react";
import "./App.scss";
import Main from "./containers/Main";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import {useLocalStorage} from "./hooks/useLocalStorage";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdYOrMkAAAAADrh3cKzvA0nyoqJ0KAzt9ux_iys">
      <ScopedCssBaseline>
        {/* Bug fix for photo carousel, cache first image. See https://github.com/Learus/react-material-ui-carousel/issues/187#issuecomment-1222069555 */}
        <img
          src={require("./assets/images/AdvisorsFABefore.png")}
          alt="img"
          style={{display: "none"}}
        />
        <img
          src={require("./assets/images/AdvisorsFAAfter.png")}
          alt="img"
          style={{display: "none"}}
        />
        <img
          src={require("./assets/images/AdvisorsCCABefore.png")}
          alt="img"
          style={{display: "none"}}
        />
        <img
          src={require("./assets/images/AdvisorsCCAAfter.png")}
          alt="img"
          style={{display: "none"}}
        />
        <div>
          <Main />
        </div>
      </ScopedCssBaseline>
    </GoogleReCaptchaProvider>
  );
}

export default App;
